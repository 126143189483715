<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                      class="c-header-nav-btn"
                      @click="openSidebarEvent('promotionFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square
                size="sm"
                @click="openModalEvent('create', 'promotionForm', null, 'Yeni Promosyon Ekle')"
              >
                Kod Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              :items="promotions"
              :fields="fields"
              :items-per-page="10"
              :loading="loading"
              hover
              pagination
              clickable-rows
              @row-clicked="rowClicked"
              @row-double-clicked="openModalEvent('update', 'promotionForm', lastItem, 'Kod Düzenle')"
              v-on:refresh="filterPromocodes"
          >
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                    toggler-text="İşlemler" color="info"
                    class="m-2"
                    size="sm"
                >
                  <div role="group">
                    <CDropdownItem @click="openModalEvent('list', 'promotionRuleList', item, 'Promosyon Kuralları')">Promosyon Kuralları</CDropdownItem>
                    <CDropdownItem @click="openModalEvent('list', 'alternativePromotionList', item, 'Alternatif Promosyon')">Alternatif Promosyonlar</CDropdownItem>
                  </div>
                  <div role="group">
                    <CDropdownItem @click="openModalEvent('update', 'promotionForm', item, 'Kod Düzenle')">Düzenle</CDropdownItem>
                    <CDropdownItem @click="openModalEvent('delete', 'confirm', item, 'Promosyon Kodunu Sil', item.code+' promosyon kodunu silmek üzeresiniz. Bunu yapmak istediğinizden emin misiniz?')">Sil</CDropdownItem>
                  </div>
                </CDropdown>
              </td>
            </template>
            <template #autoPromoFlag="{item}">
              <td>
                <CIcon v-if="item.autoPromoFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #createTime="{item}">
              <td>{{ item.createTime && dateFormat(item.createTime) }} </td>
            </template>
            <template #validDateStart="{item}">
              <td>{{ item.validDateStart && dateFormat(item.validDateStart) }} </td>
            </template>
            <template #validDateEnd="{item}">
              <td>{{  item.validDateEnd && dateFormat(item.validDateEnd) }} </td>
            </template>
            <template #validEmailFlag="{item}">
              <td>
                <CIcon v-if="item.validEmailFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="codeCrud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="modules"
      :form="form"
      :data="modalProps"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="codeCrud"
      :actionType="actionType"
      :module="modules"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterPromocodes"
      :module="modules"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
    
  </CRow>
</template>

<script>
import moment from "moment";
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import ListModal from '../components/listModal'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'Promotions',
  components: {
     ConfirmModal, FormModal, FilterSidebar, ListModal
  },
  computed:{
    loading:function(){
      return this.$store.getters.adminLoading;
    },
    promotions:function(){
      return this.$store.getters.promotions;
    },
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      modules: 'admin',
      form: 'promotionForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'promotionFilter',
      filterParams: {},
    
      show: true,
      itemsPerPage: 10,
      totalNumberOfItems: 0,
      fields: [
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'ID', _style: 'font-size:12px'},
        {key: 'code', label: 'KOD', _style: 'font-size:12px'},
        {key: 'useCount', label: 'Kullanım Adedi', _style: 'font-size:12px'},
        {key: 'maxCount', label: 'Max Kullanım Adedi', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Oluşturulma Tarihi', _style: 'font-size:12px'},
        {key: 'validDateStart', label: 'Başlangıç Tarihi', _style: 'font-size:12px'},
        {key: 'validDateEnd', label: 'Bitiş Tarihi', _style: 'font-size:12px'},
        {key: 'autoPromoFlag', label: 'Otomatik', _style: 'font-size:12px'},
        {key: 'apiType', label: 'Api Türü', _style: 'font-size:12px'},
        {key: 'apiUsername', label: 'Api Kul. Adı', _style: 'font-size:12px'},
        {key: 'apiPassword', label: 'Api Şifre', _style: 'font-size:12px'},
        {key: 'apiPriority', label: 'Api Otomatik', _style: 'font-size:12px'},
        {key: 'validDomain', label: 'Geçerli Domain', _style: 'font-size:12px'},
        {key: 'validEmailFlag', label: 'Geçerli Email', _style: 'font-size:12px'},
        {key: 'description', label: 'Açıklama', _style: 'font-size:12px'}
      ],
      filters: [
        {
          type: 'string',
          dataIndex: 'code',
          field: 'code',
        }, {
          type: 'date',
          dataIndex: 'startTime',
          field: 'createTime',
          comparison: 'gt'
        }, {
          type: 'date',
          dataIndex: 'endTime',
          field: 'createTime',
          comparison: 'lt'
        },
      ],
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){

      if (actionType == 'update' && form == 'promotionForm') {
        data.validDateStart = moment(data.validDateStart).format("YYYY-MM-DD")
        data.validDateEnd = moment(data.validDateEnd).format("YYYY-MM-DD")
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },
    rowClicked(item) {
      this.lastItem = item
    },
    async codeCrud(item, actionType) {
      item.validDateStart = moment(item.validDateStart).format("MM/DD/YYYY HH:mm");
      item.validDateEnd = moment(item.validDateEnd).format("MM/DD/YYYY HH:mm");
      let data = {
        id: item.id ? item.id : "",
        actionType : actionType,
        code: item.code.toUpperCase(),
        maxCount: item.maxCount,
        validDateStart: moment(item.validDateStart).format("MM/DD/YYYY HH:mm"),
        validDateEnd: item.validDateEnd,
        validDateEndTime: item.validDateEndTime,
        autoPromoFlag: item.autoPromoFlag,
        apiType: item.apiType,
        apiUsername: item.apiUsername,
        apiPassword: item.apiPassword,
        apiPriority: item.apiPriority,
        validEmailFlag: item.validEmailFlag,
        validDomain: item.validDomain,
        description: item.description
      };
      await this.$store.dispatch('promotion_action', data)
      if(this.$store.getters.adminStatus.success){
        await this.filterPromocodes(this.filterParams);
        this.openModalEvent()
      }
    },
    async filterPromocodes(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('promotion_list', formData)
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
    async getPromotions() {
      await this.$store.dispatch('promotion_list');
    },
  },
  created() {
    this.getPromotions();
  }
}
</script>


